import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Intro from '../components/Intro'
import Content from '../components/Content'

const Impressum = () => (
  <Layout>
    <SEO
      title="Unser Feedback-Manifest"
      keywords={[
        `Apps`,
        `Websites`,
        `Webseiten`,
        `Entwicklung`,
        `Programmierung`,
        `Homepage`,
        `Digital`,
      ]}
    />
    <Intro
      meta="Fragen. Lernen. Handeln."
      main={<>Unser Feedback-Manifest</>}
    />
    <Content>
      <h2>Warum Kundenfeedback wichtig ist</h2>
      <p>
        Bei A45 wissen wir, dass du im Mittelpunkt stehst. Dein Feedback hilft
        uns:
      </p>
      <ul>
        <li>
          <strong>Verstehen:</strong> Was gefällt dir an unseren Produkten und
          Dienstleistungen? Wo können wir uns noch verbessern?
        </li>
        <li>
          <strong>Anpassen:</strong> Wir wollen, dass unsere Produkte und
          Dienstleistungen perfekt auf deine Bedürfnisse abgestimmt sind.
        </li>
        <li>
          <strong>Vertrauen:</strong> Indem wir auf dein Feedback hören und
          handeln, zeigen wir, dass deine Meinung zählt.
        </li>
      </ul>

      <h2>Wie wir Feedback verwenden</h2>
      <p>
        Dein Feedback ist für uns ein Werkzeug zur Verbesserung. Wir nutzen für:
      </p>
      <ul>
        <li>
          <strong>Analyse:</strong> Jede Rückmeldung wird sorgfältig geprüft und
          bewertet.
        </li>
        <li>
          <strong>Produktentwicklung:</strong> Deine Anregungen fliessen direkt
          in die Verbesserung und Innovation unserer Produkte ein.
        </li>
        <li>
          <strong>Kundenerlebnis:</strong> Dank deiner Hinweise können wir unser
          gesamtes Serviceangebot optimieren.
        </li>
        <li>
          <strong>Kommunikation:</strong> Wir informieren dich regelmässig über
          die Veränderungen, die wir aufgrund deines Feedbacks vornehmen.
        </li>
      </ul>

      <h2>Unser Ziel</h2>
      <p>
        Wir wollen ein kundenorientiertes und innovatives Unternehmen sein, das
        begeistert! Mit deinem Feedback streben wir an:
      </p>
      <ul>
        <li>
          <strong>
            Exzellente Produkte und Dienstleistungen zu bieten, die du liebst.
          </strong>
        </li>
        <li>
          <strong>Langfristige, vertrauensvolle Beziehungen aufzubauen.</strong>
        </li>
        <li>
          <strong>
            Schnell und flexibel auf deine Bedürfnisse zu reagieren.
          </strong>
        </li>
        <li>
          <strong>
            Ein Vorbild für Kundenorientierung und Innovation zu sein.
          </strong>
        </li>
      </ul>
      <p>
        Teile uns deine Meinungen und Vorschläge mit! Gemeinsam können wir
        Digitales noch besser machen und Grossartiges erreichen.
      </p>
      <p>Vielen Dank für dein Vertrauen und deine Unterstützung.</p>

      <br />
      <br />
      <br />
      <br />
    </Content>
  </Layout>
)

export default Impressum
